<template>
  <div class="comment-embed" @click="jumpToComment(comment.id)">
    <comment v-if="comment" :comment="comment" niveau="0" />
    <comments-chargement v-else message="Chargement du commentaire"/>
  </div>
</template>

<script>
import Comment from "@/components/Comments/Comment.vue";
import CommentsChargement from "@/components/Comments/CommentsChargement.vue";
export default {
  components: { Comment, CommentsChargement },
  data: () => ({
    commId: false,
    comment: false,
  }),
  mounted() {
    this.commId = this.$route.params.commId;
       this.smartApi(`story/${this.storyId}/comment/${this.commId}`, {
        chargerParCdn : true,
        then(response) {
		this.comment = response.data;
	}
      });

    
/*    api.get(`story/${this.storyId}/comment/${this.commId}`).then((response) => {
      this.comment = response.data;
    });*/
  },
};
</script>

<style lang="scss">
body {
    padding: 1em;
}
.comment-embed {
    min-height: 100px;
}
.commentaire {
  position: relative;
  cursor: pointer;
  &:before {
    z-index: 1000;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>